import {
  GOOGLE_REVIEW_RATING,
  GOOGLE_REVIEW_TEXT,
  GOOGLE_REVIEW_TOTAL_REVIEWS,
  GOOGLE_REVIEW_URL,
} from '@/constants/google-reviews-constants'
import { useMonarchRule } from '@redventures/cohesion-utils-react'

interface GoogleReviews {
  data: {
    rating: number
    totalReviews: string
    linkText: string
    url: string
  }
}

const useGoogleReview = () => {
  const { value } = useMonarchRule<GoogleReviews>('googleReviews')
  const { data } = value || {}
  const ratingValue = data?.rating || GOOGLE_REVIEW_RATING
  const totalReviews = data?.totalReviews || GOOGLE_REVIEW_TOTAL_REVIEWS
  const linkText = data?.linkText || GOOGLE_REVIEW_TEXT
  const url = data?.url || GOOGLE_REVIEW_URL

  return {
    rating: ratingValue,
    totalReviews,
    linkText,
    linkUrl: url,
  }
}

export default useGoogleReview
