import React from "react"
import HeroStarRating from './HeroStarRating'
import ValuePropStarRating from '@/components/design-system/rating/ValuePropStarRating'
import NewToTxuStarRating from '@/components/design-system/rating/NewToTxuStarRating'
import HighIntentStarRating from '@/components/design-system/rating/HighIntentStarRating'
interface StarRatingBaseProps {
  variant?: 'hero' | 'value-props' | 'new-to-txu-hero' | 'high-intent';
  rating: number;
}


type StarRatingVariant = StarRatingBaseProps;

const VARIANT_COMPONENTS = {
  'hero': HeroStarRating,
  'value-props': ValuePropStarRating,
  'new-to-txu-hero': NewToTxuStarRating,
  'high-intent': HighIntentStarRating
} as const;

const StarRating: React.FC<StarRatingVariant> = ({ variant = 'hero', rating }) => {
  const Component = VARIANT_COMPONENTS[variant];
  return <Component rating={rating} />;
}

export default StarRating
