import React from "react"
import CustomIcon, { IconKeys } from '@/components/design-system/CustomIcon'
import { StarRatingProps } from "@/components/design-system/rating/interface"
import useGoogleReview from "@/hooks/useGoogleReview"

export interface GoogleReviews {
  value: {
    data: {
      rating: number
      totalReviews: string
      linkText: string
      url: string
    }
  }
}

const HeroStarRating: React.FC<StarRatingProps> = () => {
  const { rating: ratingValue, totalReviews, linkText, linkUrl: url } = useGoogleReview()

  const starsArray: string[] = Array.from({ length: 5 }, (_, index) => {
    const starValue = index + 1 // Stars are 1-indexed (1 to 5)
    if (ratingValue >= starValue) {
      return "Full"
    } else if (ratingValue > starValue - 1) {
      return "Half"
    } else {
      return "Empty"
    }
  })

  return (
    <div className="u-text-gray-700 u-flex  u-gap-1 t-body-sm u-justify-center u-items-center md:u-justify-start">
      <div className="u-h-5 u-flex u-pr-2 u-items-center u-justify-center">
        {starsArray.map((element, index) => (
          <CustomIcon
            className="u-w-4 u-p-px"
            key={index}
            icon={`star${element}` as IconKeys}
            name={`star${element}`}
            additionalClasses="c-star"
            aria-hidden="true"
            data-cy={`star-${index}`}
          />
        ))}
      </div>
      <div className="u-flex u-gap-x-1 u-flex-wrap md:u-flex-nowrap u-border-l u-border-gray-400 u-pl-4 u-w-6/12 md:u-w-8/12">
        <span>Rated</span>{' '}<span className="u-font-700">{ratingValue}/5</span>{' '}<span>|</span><span>{totalReviews}</span>
        <a
          className="u-text-blue-500 u-underline md:u-whitespace-nowrap"
          target="_blank"
          href={url}
        >
          {linkText}
        </a>
      </div>
    </div>
  )
}

export default HeroStarRating
