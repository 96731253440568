import Apartment from '../../../public/images/icons/custom/apartment.svg'
import ApartmentWhite from '../../../public/images/icons/custom/apartment-white.svg'
import Appliance from '../../../public/images/icons/custom/appliance.svg'
import ApplianceWhite from '../../../public/images/icons/custom/appliance-white.svg'
import ArrowLeft from '../../../public/images/icons/custom/arrow-left.svg'
import ArrowLeftWhite from '../../../public/images/icons/custom/arrow-left-white.svg'
import ArrowRight from '../../../public/images/icons/custom/arrow-right.svg'
import ArrowRightThin from '../../../public/images/icons/custom/arrow-right-thin.svg'
import ArrowRightSmooth from '../../../public/images/icons/custom/arrow-right-smooth.svg'
import ArrowRightWhite from '../../../public/images/icons/custom/arrow-right-white.svg'
import Business from '../../../public/images/icons/custom/business.svg'
import BusinessWhite from '../../../public/images/icons/custom/business-white.svg'
import CellPhone from '../../../public/images/icons/custom/cellphone.svg'
import DollarCircle from '../../../public/images/icons/custom/dollar-circle.svg'
import Download from '../../../public/images/icons/custom/download.svg'
import Google from '../../../public/images/icons/custom/google.svg'
import GoogleWhite from '../../../public/images/icons/custom/google-white.svg'
import GreenCheck from '../../../public/images/icons/custom/green-check.svg'
import Home from '../../../public/images/icons/custom/home.svg'
import HomeWhite from '../../../public/images/icons/custom/home-white.svg'
import Plans from '../../../public/images/icons/custom/plans.svg'
import PlansWhite from '../../../public/images/icons/custom/plans-white.svg'
import Plus from '../../../public/images/icons/custom/plus.svg'
import PriceProtect from '../../../public/images/icons/custom/price-protect.svg'
import PriceProtectWhite from '../../../public/images/icons/custom/price-protect-white.svg'
import Quote from '../../../public/images/icons/custom/quote.svg'
import QuoteWhite from '../../../public/images/icons/custom/quote-white.svg'
import Satisfaction from '../../../public/images/icons/custom/satisfaction.svg'
import SatisfactionWhite from '../../../public/images/icons/custom/satisfaction-white.svg'
import SevereWeather from '../../../public/images/icons/custom/severe-weather.svg'
import SevereWeatherWhite from '../../../public/images/icons/custom/severe-weather-white.svg'
import SevereWeatherColor2 from '../../../public/images/icons/custom/severe-weather-color2.svg'
import SevereWeatherColor3 from '../../../public/images/icons/custom/severe-weather-color3.svg'
import SignIn from '../../../public/images/icons/custom/sign-in.svg'
import SignInWhite from '../../../public/images/icons/custom/sign-in-white.svg'
import SignInBlue from '../../../public/images/icons/custom/sign-in-blue.svg'
import StarEmpty from '../../../public/images/icons/custom/star-empty.svg'
import StarFull from '../../../public/images/icons/custom/star-full.svg'
import StarHalf from '../../../public/images/icons/custom/star-half.svg'
import StormUsageAlert from '../../../public/images/icons/custom/storm-usage-alert.svg'
import StormUsageAlertWhite from '../../../public/images/icons/custom/storm-usage-alert-white.svg'
import Support from '../../../public/images/icons/custom/support.svg'
import SupportWhite from '../../../public/images/icons/custom/support-white.svg'
import Thunderstorm from '../../../public/images/icons/custom/thunderstorm.svg'
import ThunderstormWhite from '../../../public/images/icons/custom/thunderstorm-white.svg'
import UsageAlert from '../../../public/images/icons/custom/usage-alert.svg'
import UsageAlertWhite from '../../../public/images/icons/custom/usage-alert-white.svg'
import Window from '../../../public/images/icons/custom/window.svg'
import WindowWhite from '../../../public/images/icons/custom/window-white.svg'
import WinterUsageAlert from '../../../public/images/icons/custom/winter-usage-alert.svg'
import WinterUsageAlertWhite from '../../../public/images/icons/custom/winter-usage-alert-white.svg'
import WinterUsageAlertColor2 from '../../../public/images/icons/custom/winter-usage-alert-color2.svg'
import WinterUsageAlertColor3 from '../../../public/images/icons/custom/winter-usage-alert-color3.svg'
import SameDay from '../../../public/images/icons/custom/same-day.svg'
import Shield from '../../../public/images/icons/custom/shield.svg'
import Paid from '../../../public/images/icons/custom/paid.svg'

export type IconKeys =
  | 'apartment'
  | 'apartmentWhite'
  | 'appliance'
  | 'applianceWhite'
  | 'arrowLeft'
  | 'arrowLeftWhite'
  | 'arrowRight'
  | 'arrowRightThin'
  | 'arrowRightSmooth'
  | 'arrowRightWhite'
  | 'business'
  | 'businessWhite'
  | 'cellPhone'
  | 'dollarCircle'
  | 'download'
  | 'google'
  | 'googleWhite'
  | 'greenCheck'
  | 'home'
  | 'homeWhite'
  | 'paid'
  | 'plans'
  | 'plansWhite'
  | 'plus'
  | 'priceProtect'
  | 'priceProtectWhite'
  | 'quote'
  | 'quoteWhite'
  | 'sameDay'
  | 'satisfaction'
  | 'satisfactionWhite'
  | 'severeWeather'
  | 'severeWeatherWhite'
  | 'severeWeatherColor2'
  | 'severeWeatherColor3'
  | 'signIn'
  | 'signInWhite'
  | 'signInBlue'
  | 'shield'
  | 'starEmpty'
  | 'starFull'
  | 'starHalf'
  | 'stormUsageAlert'
  | 'stormUsageAlertWhite'
  | 'support'
  | 'supportWhite'
  | 'thunderstorm'
  | 'thunderstormWhite'
  | 'usageAlert'
  | 'usageAlertWhite'
  | 'window'
  | 'windowWhite'
  | 'winterUsageAlert'
  | 'winterUsageAlertWhite'
  | 'winterUsageAlertColor2'
  | 'winterUsageAlertColor3';

export type CustomIconProps = React.HTMLProps<HTMLImageElement> & {
  additionalClasses?: string;
  icon: IconKeys;
  ariaLabel?: string;
};

export default function CustomIcon({ additionalClasses = '', icon, ariaLabel, ...props }: CustomIconProps) {
  const iconsMap: Record<IconKeys, React.FC<{ className: string, role?: string }>> = {
    apartment: Apartment,
    apartmentWhite: ApartmentWhite,
    appliance: Appliance,
    applianceWhite: ApplianceWhite,
    arrowLeft: ArrowLeft,
    arrowLeftWhite: ArrowLeftWhite,
    arrowRight: ArrowRight,
    arrowRightThin: ArrowRightThin,
    arrowRightSmooth: ArrowRightSmooth,
    arrowRightWhite: ArrowRightWhite,
    business: Business,
    businessWhite: BusinessWhite,
    cellPhone: CellPhone,
    download: Download,
    dollarCircle: DollarCircle,
    google: Google,
    googleWhite: GoogleWhite,
    greenCheck: GreenCheck,
    home: Home,
    homeWhite: HomeWhite,
    paid: Paid,
    plans: Plans,
    plansWhite: PlansWhite,
    plus: Plus,
    priceProtect: PriceProtect,
    priceProtectWhite: PriceProtectWhite,
    quote: Quote,
    quoteWhite: QuoteWhite,
    sameDay: SameDay,
    satisfaction: Satisfaction,
    satisfactionWhite: SatisfactionWhite,
    severeWeather: SevereWeather,
    severeWeatherWhite: SevereWeatherWhite,
    severeWeatherColor2: SevereWeatherColor2,
    severeWeatherColor3: SevereWeatherColor3,
    signIn: SignIn,
    signInWhite: SignInWhite,
    signInBlue: SignInBlue,
    shield: Shield,
    starEmpty: StarEmpty,
    starFull: StarFull,
    starHalf: StarHalf,
    stormUsageAlert: StormUsageAlert,
    stormUsageAlertWhite: StormUsageAlertWhite,
    support: Support,
    supportWhite: SupportWhite,
    thunderstorm: Thunderstorm,
    thunderstormWhite: ThunderstormWhite,
    usageAlert: UsageAlert,
    usageAlertWhite: UsageAlertWhite,
    window: Window,
    windowWhite: WindowWhite,
    winterUsageAlert: WinterUsageAlert,
    winterUsageAlertWhite: WinterUsageAlertWhite,
    winterUsageAlertColor2: WinterUsageAlertColor2,
    winterUsageAlertColor3: WinterUsageAlertColor3,
  };

  const SelectedIcon = iconsMap[icon];

  return (
    <SelectedIcon
      className={`c-custom-icon ${additionalClasses}`}
      aria-hidden={ariaLabel ? 'false' : 'true'}
      aria-label={ariaLabel}
      role="img"
      {...props}
    />
  );
}
