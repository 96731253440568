import useGoogleReview from '@/hooks/useGoogleReview'
import CustomIcon, { IconKeys } from '@/components/design-system/CustomIcon'
import React from 'react'

const NewToTxuStarRating = () => {
  const { rating: ratingValue, totalReviews, linkText, linkUrl: url } = useGoogleReview()

  const starsArray: string[] = Array.from({ length: 5 }, (_, index) => {
    const starValue = index + 1
    if (ratingValue >= starValue) {
      return "Full"
    } else if (ratingValue > starValue - 1) {
      return "Half"
    } else {
      return "Empty"
    }
  })

  return (
    <div className="u-text-white u-flex  u-gap-2 t-body-sm md:t-body-base u-items-center md:u-justify-start u-h-[44px] md:u-h-[48px]">
      <div className="u-h-5 u-flex u-items-center u-justify-center">
        {starsArray.map((element, index) => (
          <CustomIcon
            className="u-w-4 u-h-4 u-p-px md:u-h-6 md:u-w-6"
            key={index}
            icon={`star${element}` as IconKeys}
            name={`star${element}`}
            additionalClasses="c-star"
            aria-hidden="true"
            data-cy={`star-${index}`}
          />
        ))}
      </div>
      <div className="u-w-4 md:u-w-6 md:u-h-6">
        <CustomIcon className="u-w-4 u-h-4 md:u-w-6 md:u-h-6" icon="googleWhite" />
      </div>
      <div className="u-flex u-gap-x-1 u-flex-nowrap u-w-6/12 md:u-w-8/12">
        <span>Rated</span>{' '}<span className="u-font-700">{ratingValue}/5</span>{' '}<span>|</span><span>{totalReviews}</span>
        <a
          className="u-text-white u-underline u-whitespace-nowrap"
          target="_blank"
          href={url}
        >
          {linkText}
        </a>
      </div>
    </div>
  )
}

export default NewToTxuStarRating
